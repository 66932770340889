exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-reports-js": () => import("./../../../src/pages/case-reports.js" /* webpackChunkName: "component---src-pages-case-reports-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-gateway-js": () => import("./../../../src/pages/gateway.js" /* webpackChunkName: "component---src-pages-gateway-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-subject-area-[course]-[module]-[resource]-[resource]-index-js": () => import("./../../../src/pages/learn/[subjectArea]/[course]/[module]/[resource]/[resource]/index.js" /* webpackChunkName: "component---src-pages-learn-subject-area-[course]-[module]-[resource]-[resource]-index-js" */),
  "component---src-pages-learn-subject-area-[course]-[module]-index-js": () => import("./../../../src/pages/learn/[subjectArea]/[course]/[module]/index.js" /* webpackChunkName: "component---src-pages-learn-subject-area-[course]-[module]-index-js" */),
  "component---src-pages-learn-subject-area-index-js": () => import("./../../../src/pages/learn/[subjectArea]/index.js" /* webpackChunkName: "component---src-pages-learn-subject-area-index-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-quick-reference-js": () => import("./../../../src/pages/quick-reference.js" /* webpackChunkName: "component---src-pages-quick-reference-js" */),
  "component---src-pages-research-spotlight-js": () => import("./../../../src/pages/research-spotlight.js" /* webpackChunkName: "component---src-pages-research-spotlight-js" */),
  "component---src-pages-style-guide-js": () => import("./../../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-topic-js": () => import("./../../../src/pages/topic.js" /* webpackChunkName: "component---src-pages-topic-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-case-report-js": () => import("./../../../src/templates/case-report.js" /* webpackChunkName: "component---src-templates-case-report-js" */),
  "component---src-templates-quick-reference-js": () => import("./../../../src/templates/quick-reference.js" /* webpackChunkName: "component---src-templates-quick-reference-js" */),
  "component---src-templates-research-spotlight-js": () => import("./../../../src/templates/research-spotlight.js" /* webpackChunkName: "component---src-templates-research-spotlight-js" */)
}

